.parent-container {
   width: 100%;
   overflow: hidden; /* Confine the moving container within its parent */
   background-color: rgb(11, 4, 4,0);
 }
/*   */
 
 .moving-container {
   position: relative;
   overflow: hidden; /* Ensure the moving container doesn't overflow the parent */
   background-color: rgb(190, 193, 23);
 }
 
 .moving-characters {
   position: relative;
   white-space: nowrap;
   animation: moveLeftRight 20s linear infinite alternate;
   background-color: rgb(255, 0, 0,0);
   
 }
 
  @keyframes moveLeftRight {
   0% {
     transform: translateX(-100%);
   }
   100% {
     transform: translateX(100%);
   }
 } 
 