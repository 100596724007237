#card-container {
  transition: transform 0.3s ease-in-out;
  height: fit-content;
}

#card-container:hover {
  transform: scale(1.05); /* Adjust the scale factor as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow effect */
}

#image-container {
  transition: transform 0.3s ease-in-out;
}

#image-container:hover {
  transform: scale(1.0.5); /* Adjust the scale factor as needed */
}
