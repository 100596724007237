.image-viewer {
   display: flex;
   flex-direction: column;
   align-items: center;
 }
 
 .image-thumbnails {
   display: flex;
   flex-wrap: wrap;
   gap: 10px;
 }
 
 .thumbnail {
   width: 100px;
   height: auto;
   cursor: pointer;
   transition: transform 0.2s;
 }
 
 .thumbnail:hover {
   transform: scale(1.05);
 }
 
 .modal {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.8);
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 /* .modal-image {
 } */
 
 .close-button {
   position: absolute;
   top: 20px;
   right: 20px;
   background: none;
   border: none;
   cursor: pointer;
   color: white;
   font-size: 24px;
 }
 
 .nav-button {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background: none;
   border: none;
   color: white;
   font-size: 30px;
   cursor: pointer;
   z-index: 10;
 }
 
 .nav-button.left {
   left: 20px;
   color:rgb(38, 255, 0);
 }
 
 .nav-button.right {
   right: 20px;
   color:rgb(38, 255, 0);
 }
 .zoom-button {
   position: absolute;
   bottom: 20px;
   cursor: pointer;
   z-index: 10;
 }
 
 .zoom-button.zoom-in {
   left:51%; /* Position for zoom in button */
 }
 
 .zoom-button.zoom-out {
   right: 51%; /* Position for zoom out button */
 }
 
 /* .image-container {
   position: relative;
   overflow: hidden;
   width: fit-content;
   height: 90%;
 } */
 
 .fade {
   animation: fade-out 0.3s forwards;
 }
 
 @keyframes fade-out {
   0% {
     opacity: 1;
   }
   100% {
     opacity: 0;
   }
 }
 
 .modal-image {
   transition: opacity 0.3s ease-in-out; /* Smooth transition for image */
   max-width: 95vw;
   max-height: 550px;
   border-radius: 4px;
 }
 
 .indicators {
   display: flex;
   justify-content: center;
   margin-top: 10px;
   position: absolute;
   bottom: 5rem;
 }
 
 .indicator {
   width: 10px;
   height: 10px;
   border-radius: 50%;
   background: gray;
   margin: 0 5px;
   cursor: pointer;
 }
 
 .indicator.active {
   background: rgb(38, 255, 0); /* Color for the active indicator */
 }
 